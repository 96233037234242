import { useEffect } from 'react'
import { Loading } from '@components'
import AppQuizIndexModule from '@modules/App/Quiz/Index'
import useAuth from '@utils/auth/client'

const AppQuizPage = () => {
  const {
    authState: { loading, user },
    redirectToSignin,
    redirectToSignUp,
    redirectToCompletingSignUp,
  } = useAuth()

  useEffect(() => {
    if (!loading && !user) redirectToSignin()
    if (!loading && user && !user.phoneNumber) {
      const param = {}
      if (user.email) param.email = user.email
      if (user.name) param.name = user.name
      redirectToSignUp(param)
    }
  }, [
    loading,
    user,
    redirectToSignin,
    redirectToSignUp,
    redirectToCompletingSignUp,
  ])

  if (loading) return <Loading isPageMode />

  return <AppQuizIndexModule />
}

export default AppQuizPage
