import gql from 'graphql-tag'

import {
  Option,
  OptionsOrder,
  Question,
  QuizAnswerInfo,
  QuizAnswerResult,
  Ingredient,
  Product,
  Category,
  Subcategory,
  Bundle,
  Discount,
  ProductScore,
} from './fragments'

export const getSkinQuizQuestion = gql`
  query getSkinQuizQuestion {
    quizQuestion {
      id
      order
      questionGroupName

      # Question
      question {
        ...Question
        options {
          ...OptionsOrder
          option {
            ...Option
          }
        }
      }
    }
  }
  ${Option}
  ${OptionsOrder}
  ${Question}
`

export const getSurveyQuestions = gql`
  query getSurveyQuestions {
    surveyQuestions: surveyQuestion {
      question {
        ...Question

        options {
          option {
            ...Option
          }
        }
      }
    }
  }
  ${Option}
  ${Question}
`

export const getLatestQuizResult = gql`
  query getLatestQuizResult {
    latestQuizResult {
      id
    }
  }
`

export const getQuizResults = gql`
  query getQuizResults($limit: Int, $after: String) {
    result: quizResults(limit: $limit, after: $after) {
      meta {
        count
        pagination {
          hasNext
          lastCursor
        }
      }
      quizResults {
        id
        createdAt
      }
    }
  }
`

export const getQuizResultForAnalysis = gql`
  query getQuizResult($id: String!) {
    quizResult: quizResultById(id: $id) {
      ...QuizAnswerInfo

      results {
        ...QuizAnswerResult
      }
      ingredients {
        ...Ingredient
      }
    }
  }
  ${QuizAnswerInfo}
  ${QuizAnswerResult}
  ${Ingredient}
`

export const getQuizResultForIngredient = gql`
  query getQuizResult($id: String!) {
    quizResult: quizResultById(id: $id) {
      ...QuizAnswerInfo

      ingredients {
        ...Ingredient
      }
    }
  }
  ${QuizAnswerInfo}
  ${Ingredient}
`

export const getQuizResultForProductReco = gql`
  query getQuizResult($id: String!) {
    quizResult: quizResultById(id: $id) {
      ...QuizAnswerInfo

      products {
        ...Product
        availableStock

        category {
          ...Category
        }
        subcategory {
          ...Subcategory
        }
        ingredients {
          ...Ingredient
        }
        heroIngredients {
          ...Ingredient
        }
        score {
          ...ProductScore
        }
      }

      bundles {
        ...Bundle

        discount {
          ...Discount
        }
        subcategories {
          ...Subcategory
        }
        products {
          ...Product
          availableStock

          category {
            ...Category
          }
          subcategory {
            ...Subcategory
          }
          ingredients {
            ...Ingredient
          }
          score {
            ...ProductScore
          }
        }
      }
    }
  }
  ${QuizAnswerInfo}
  ${Product}
  ${Category}
  ${Subcategory}
  ${Ingredient}
  ${Bundle}
  ${Discount}
  ${ProductScore}
`

export const getOrderSurveyResult = gql`
  query getOrderSurveyResult($orderId: String!) {
    surveyResults: orderProductSurvey(orderId: $orderId) {
      orderId
      productId
      surveyId
    }
  }
`

export const getProductReview = gql`
  query getProductReview(
    $productIds: [String]!
    $limit: Int!
    $page: Int!
    $order: OrderReviewType
  ) {
    result: productReview(
      productIds: $productIds
      limit: $limit
      page: $page
      order: $order
    ) {
      meta {
        page
        pageTotal
        count
        order
      }
      reviews {
        userDisplayName
        productId
        surveyId
        totalScore
        productName
        comment: review
        createdAt
      }
    }
  }
`
