import { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { useRouter } from 'next/router'

import Loading from '@components/Loading'

import { getLatestQuizResult } from '@gql/quizQuery'

function AppQuizIndexModule() {
  const router = useRouter()
  const { loading, data: { latestQuizResult } = {} } = useQuery(getLatestQuizResult)

  useEffect(() => {
    if (!loading && latestQuizResult) {
      router.push(`/app/quiz/result/${latestQuizResult.id}/analysis`)
    }
    if (!loading && !latestQuizResult) {
      router.push('/quiz/intro')
    }
  }, [loading, latestQuizResult, router])

  return <Loading isPageMode />
}

export default AppQuizIndexModule
