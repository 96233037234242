import { memo } from 'react'
import PropTypes from 'prop-types'

import styles from './style.module.css'

function Loading({ containerClassName, className, isPageMode, size }) {
  return (
    <div
      className={`
      ${styles.container}
      ${isPageMode ? styles.page : ''}
      ${containerClassName || ''}
    `}
    >
      <div className={`${styles.loading} ${styles[size]} ${className || ''}`} />
    </div>
  )
}

Loading.propTypes = {
  containerClassName: PropTypes.string,
  className: PropTypes.string,
  isPageMode: PropTypes.bool,
  size: PropTypes.oneOf(['normal', 'small', 'large']),
}

Loading.defaultProps = {
  size: 'normal',
}

export default memo(Loading)
